import type { AxiosRequestConfig } from 'axios'
import type { Ref } from 'vue'
import { ref } from 'vue'
import GlobalAxiosInstance from '@/service/request/instance'
import type {
  RequestError,
  RequestMethod,
  RequestResult,
} from '@/service/request/index.interface'
import { getRequestResponse } from '@/utils/requestUtils'
import useLoading from '@/hooks/useLoading'

interface RequestParam {
  url: string
  method?: RequestMethod
  data?: any
  axiosConfig?: AxiosRequestConfig
}

interface RequestResultHook<T = any> {
  data: Ref<T | null>
  error: Ref<RequestError | null>
  loading: Ref<boolean>
}

export function createHookRequest(axiosConfig: AxiosRequestConfig) {
  const requestInstance = new GlobalAxiosInstance(axiosConfig)

  function useRequest<T>(param: RequestParam): RequestResultHook<T> {
    const { loading, startLoading, endLoading } = useLoading()

    startLoading()

    const data: Ref<T | null> = ref(null)
    const error: Ref<RequestError | null> = ref(null)

    const { url } = param
    const method = param.method || 'get'
    const { instance } = requestInstance

    getRequestResponse({ instance, method, url, data: param.data, config: param.axiosConfig }).then((response: any) => {
      const res = response as RequestResult<T>
      data.value = res.data
      error.value = res.error
      endLoading()
    })

    return {
      data,
      error,
      loading,
    }
  }

  // get
  function get<T>(url: string, config?: AxiosRequestConfig) {
    return useRequest<T>({ url, method: 'get', axiosConfig: config })
  }

  // post
  function post<T>(url: string, data?: any, config?: AxiosRequestConfig) {
    return useRequest<T>({
      url,
      method: 'post',
      data,
      axiosConfig: config,
    })
  }

  // put
  function put<T>(url: string, data?: any, config?: AxiosRequestConfig) {
    return useRequest<T>({ url, method: 'put', data, axiosConfig: config })
  }

  // delete
  function handleDelete<T>(url: string, config?: AxiosRequestConfig) {
    return useRequest<T>({ url, method: 'delete', axiosConfig: config })
  }

  return {
    get,
    post,
    put,
    delete: handleDelete,
  }
}
