import { get, post } from '../service'

// 文件快传接口
export function filesFast(data: any) {
  return post<any>('/storage/api/v1/files/fast', data)
}
  
// 文件上传接口
export function filesUpload(data: any) {
  return post<any>('/storage/api/v1/files', data)
}
// 发送验证码
export function sendUserCaptcha(data: any) {
  return post<any>('/access/api/v1/sms/send-captcha', data)
}
// 文件上传接口
export function loginUserRegister(data: any) {
  return post<any>('/access/api/v1/sms-login-register', data)
}
// 用户注销
export function logoutUser(data: any) {
  return post<any>('/access/api/v1/logout', data)
}
// 意见反馈
export function feedbackCreate(data: any) {
  return post<any>('/large-model/api/v1/feedback/create', data)
}
// 祝福生成
export function activityGenerate(data: any) {
  return post<any>('/large-model/api/v1/activity-bless/generate', data)
}
//对话终止
export function activityBlessGenerate(data: {
  talkId: any
}) {
  return post<any>('/large-model/api/v1/chat-message/process-terminate', data)
}
