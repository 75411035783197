import axios from 'axios'
import type {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios'
import { API_BACKENDSTATUS_WHITELIST } from '../guard/constants'
import type { BackendResultConfig } from './index.interface'
import {
  handleAxiosError,
  handleBackendError,
  handleResponseError,
} from './error'
import { handleServiceResult } from './handler'

export default class GlobalAxiosInstance {
  instance: AxiosInstance
  backendConfig: BackendResultConfig
  constructor(
    axiosConfig: AxiosRequestConfig,
    backendConfig: BackendResultConfig = {
      codeKey: 'code',
      dataKey: 'data',
      msgKey: 'message',
      successCode: 200,
    },
  ) {
    this.instance = axios.create(axiosConfig)
    this.backendConfig = backendConfig
    this.setInterceptors()
  }

  setInterceptors() {
    this.instance.interceptors.request.use(
      async (config) => {
        config.headers['x-auth-token'] = localStorage.getItem('token')
        return config
      },
      (error: AxiosError) => {
        const axiosError = handleAxiosError(error)
        return handleServiceResult(axiosError, null, undefined)
      },
    )
    this.instance.interceptors.response.use(
      (async (response: AxiosResponse<any, any>) => {
        const { status, data, config } = response
        if (status === 200) {
          if (data.status === 401) 
            localStorage.setItem('loginVisible', 'true')
          
          if (config.responseType === 'arraybuffer')
            return handleServiceResult(null, response, 200)
          
          if (data.status === 200)
            return handleServiceResult(null, data.data, data.status)

          if (Object.keys(API_BACKENDSTATUS_WHITELIST).includes(config.url as string) && API_BACKENDSTATUS_WHITELIST[config.url as string].includes(data.status))
            return handleServiceResult(null, data.data, data.status)

          // 后端返回报错
          const error = handleBackendError(data, this.backendConfig)
          return handleServiceResult(error, null, data.status)
        }
        
        // 接口请求报错
        const error = handleResponseError(data)
        return handleServiceResult(error, null)
      }) as unknown as (response: AxiosResponse<any, any>) => Promise<AxiosResponse<any, any>>,
      (error: AxiosError) => {
        const axiosError = handleAxiosError(error)
        return handleServiceResult(axiosError, null)
      },
    )
  }
}
