import type { Ref } from 'vue'
import { ref } from 'vue'

export default function useBoolean(
  defaultValue = false,
): {
    bool: Ref<boolean>
    methods: {
      setTrue: () => void
      setFalse: () => void
      toggle: () => void
    }
  } {
  const booleanRef: Ref<boolean> = ref(defaultValue)
  const methods = {
    setTrue: () => {
      booleanRef.value = true
    },
    setFalse: () => {
      booleanRef.value = false
    },
    toggle: () => {
      booleanRef.value = !booleanRef.value
    },
  }
  return {
    bool: booleanRef,
    methods,
  }
}
