import useBoolean from './useBoolean'

export default function useLoading(defaultValue = false) {
  const { bool, methods } = useBoolean(defaultValue)

  return {
    loading: bool,
    startLoading: methods.setTrue,
    endLoading: methods.setFalse,
  }
}
