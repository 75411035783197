import type {
  AxiosInstance,
  AxiosRequestConfig,
} from 'axios'
import type { RequestMethod } from '@/service/request/index.interface'

export async function getRequestResponse(params: {
  instance: AxiosInstance
  method: RequestMethod
  url: string
  data?: any
  config?: AxiosRequestConfig
}) {
  const { instance, method, url, data, config } = params

  let res: any
  if (method === 'get' || method === 'delete') 
    res = await instance[method](url, config)
  
  else 
    res = await instance[method](url, data, config)
  
  return res
}
