/* eslint-disable unused-imports/no-unused-vars */
import type { AxiosError, AxiosResponse } from 'axios'
import type { BackendResultConfig, RequestError } from './index.interface'
import { showErrorMsg } from './handler'
import {
  DEFAULT_REQUEST_ERROR_CODE,
  DEFAULT_REQUEST_ERROR_TEXT,
  ERROR_STATUS_HASH,
  NETWORK_ERROR_CODE,
  NETWORK_ERROR_TEXT,
} from '@/config/service'

type ErrorStatus = keyof typeof ERROR_STATUS_HASH

export function handleAxiosError(axiosError: AxiosError) {
  const error: RequestError = {
    code: DEFAULT_REQUEST_ERROR_CODE,
    msg: DEFAULT_REQUEST_ERROR_TEXT,
  }

  showErrorMsg(error)
  return error
}

export function handleResponseError(response: AxiosResponse) {
  const reponseStatus = response.status as ErrorStatus
  const error: RequestError = {
    code: DEFAULT_REQUEST_ERROR_CODE,
    msg: DEFAULT_REQUEST_ERROR_TEXT,
  }
  if (!window.navigator.onLine) {
    Object.assign(error, {
      code: NETWORK_ERROR_CODE,
      msg: NETWORK_ERROR_TEXT,
    })
  }
  else {
    const msg = ERROR_STATUS_HASH[reponseStatus] || DEFAULT_REQUEST_ERROR_TEXT
    Object.assign(error, {
      code: reponseStatus,
      msg,
    })
  }
  showErrorMsg(error)
  return error
}

export function handleBackendError(backendResult: Record<string, any>, config: BackendResultConfig) {
  const { codeKey, msgKey } = config
  const error: RequestError = {
    code: backendResult[codeKey],
    msg: backendResult[msgKey],
  }

  showErrorMsg(error)

  return error
}
