function setRem() {
  //pc设计图尺寸是1920,默认字体大小16px  移动端是375
  let scale = 0
  if (document.documentElement.clientWidth > 768)
    scale = 10

  else
    scale = 375 / 210

  const htmlWidth = document.documentElement.clientWidth || document.body.clientWidth
  // 得到html的Dom元素
  const htmlDom = document.getElementsByTagName('html')[0]
  // 设置根元素字体大小
  htmlDom.style.fontSize = `${htmlWidth / scale  }px`
}

// 初始化
setRem()

// 改变窗口大小时重新设置 rem
window.addEventListener('resize', setRem, false)

window.onload = function () {
  setRem()
}
