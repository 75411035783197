import { createRequest } from './request/request'
import { createHookRequest } from '@/hooks/useLoadingRequest'

export const request = createRequest({ baseURL: '/' })

export const hookRequest = createHookRequest({ baseURL: '/' })

export const get = request.get

export const post = request.post

export const handleDelete = request.delete
