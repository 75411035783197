<script setup lang="ts">
import { onMounted, reactive } from 'vue'
import { Message, Modal } from '@arco-design/web-vue'
import { loginUserRegister, logoutUser, sendUserCaptcha } from '@/api/user'
import chatMessageImg from '@/assets/img/layout/youwen-icon.png'
import biChatImg from '@/assets/img/layout/shuju-icon.png'
import technologyAi from '@/assets/img/layout/gongyi-icon.png'
import intellectualAnalysis from '@/assets/img/layout/zhixi-icon.png'
import detectionPicture from '@/assets/img/layout/zhijian-icon.png'
import traffic from '@/assets/img/layout/jiaotong-icon.png'

const mobile  = ref<string>('')
const router = useRouter()
const route = useRoute()
const isCollapse = ref(false)
// 配置表单ref
const loginForm = ref<any>(null)
// 登录表单
interface Form  {
  phone: any
  code: any
}
const form = ref<Form>({
  phone:null,
  code:null,
})
const rules = {
  phone:[{
    required: true,
    message:'请输入正确的电话号码',
    match:/^(1[345789]\d{9})$/,
  }],
}
// 倒计时
const countdown = ref<number>(59)
// 是否发送验证码
const isSendCode = ref<boolean>(false)
// 计时器
const setInter = ref<any>(null)
// 是否选中协议
const checkedCode = ref<boolean>(false)
// 是否登录
const isLogin = ref<boolean>(false)
// 验证码
const captchaId = ref<string>('')
// 选中的menu key
const selectKey = ref('')
const loginVisible = ref(false)
const menuVisible = ref(false)
const menuObj = ref({
  title:'',
  url:'',
})
// 推荐列表
const recommendList = ref([
  {
    name:'chatMessage',
    url: chatMessageImg,
    title:'有问必答',
    desc:'基于自研悠然大模型，搭载企业级本地知识库问答解决方案，带来基于文件和图片的高精度问答体验。',
  },
  {
    name:'biChat',
    url: biChatImg,
    title:'数据灵狐',
    desc:'实现数据随时穿透，做到“问答即洞察”，解决数据报表和报告整理的难题，提供多种图表样式、报告内容模版按需组合',
  },
  {
    name:'technologyAi',
    url: technologyAi,
    title:'工艺智卡',
    desc:'依赖优越的工业大模型能力，为工业标准化作业场景带来先进生产力工具，一键自动生成工艺卡片，提供多种模版选择。',
  },
  {
    name:'intellectualAnalysis',
    url: intellectualAnalysis,
    title:'智析视界',
    desc:'基于悠然大模型的多模态理解能力，实现对视频的全量识别与理解推理，并提供对话式视频内容检索与研判能力。',
  },
  {
    name:'detectionPicture',
    url: detectionPicture,
    title:'维检通',
    desc:'烟草大模型赋能车间质检，AI精准识别烟包缺陷分类，定位故障并指导检修，提升生产智能化。',
  },
  {
    name:'trafficList',
    url: traffic,
    title:'智析快处',
    desc:'专业级智能自动化网页操作工具，依托交通大模型一键式执行交通事件重复处置研判任务，大幅提升监管工作效率。',
  },
])

// 跳转路由
function linkeToRoute(key: string, index: number) {
  selectKey.value = key
  menuObj.value = recommendList.value[index]
  router.push({
    name:key,
  })

}
// 退出登录
function logout() {
  Modal.confirm({
    title: '提示',
    content: '确认退出账号?',
    onOk: async () => {
      await  logoutUser({}).then((res) => {
        if (res.status === 200) {
          Message.success('退出成功')
          localStorage.removeItem('token')
          localStorage.removeItem('mobile')
          localStorage.removeItem('routes')
          if (!localStorage.getItem('isVisited')) 
            localStorage.removeItem('isVisited')
          isLogin.value = false
          loginVisible.value = true
        }
      })
    },
    onCancel: () => {
      Message.info('已取消删除')
    },
  })

}
// 打开登录弹框
function login() {
  loginVisible.value = true
  loginForm.value.resetFields()
}
// 登录
function loginRegister() {
  const mobileNum = form.value.phone
  loginForm.value.validate(async (valid)=>{
    if (!valid) {
      const params = {
        captcha: form.value.code,
        captchaId: captchaId.value,
        mobile: form.value.phone,
      }
      if (!checkedCode.value) {
        Message.error('请先勾选协议')
        return
      }
      loginUserRegister(params).then((res) => {
        if (res.status === 200) {
          clearInterval(setInter.value)
          isSendCode.value = false
          isLogin.value = true
          Message.success('登录成功')
          loginVisible.value = false
          mobile.value = `${mobileNum.substr(0, 3)  }****${  mobileNum.substr(7, 4)}`
          localStorage.setItem('token', res.data.token)
          localStorage.setItem('mobile', mobile.value)
          if (localStorage.getItem('loginVisible'))
            localStorage.removeItem('loginVisible')
          location.reload()
        }
      })
      loginForm.value.resetFields()
    }
  })
}
// 获取验证码
function sendCaptcha() {
  loginForm.value.validate(async (valid)=>{
    if (!valid) {
      isSendCode.value = true //开始倒计时
      countdown.value = 59
      setInter.value = setInterval(() => {
        countdown.value = countdown.value - 1
        if (countdown.value === 0)
          clearInterval(setInter.value)
      }, 1000)
      sendUserCaptcha({ mobile: form.value.phone, type: 'SMS_LOGIN_REGISTER' }).then((res) => {
        if (res.status === 200) {
          captchaId.value = res.data.id
        }
        else {
          clearInterval(setInter.value)
          isSendCode.value = false
          countdown.value = 59
        }
      })
    }
  })
}
// 服务协议
function serviceAgreement() {
  window.open(`${location.origin}/large-model/serviceAgreement`)
}
// 隐私政策
function privacyPolicy() {
  window.open(`${location.origin}/large-model/privacyPolicy`)
}
// 意见反馈
function goFeedback() {
  if (!isLogin.value)
    loginVisible.value = true
  else
    window.open(`${location.origin}/large-model/feedback`)
}
// 回到首页
function goHome() {
  router.push({ path:'/home' })
}
// 监听
watch(() => route.name, (newName) => {
  selectKey.value = newName
  menuVisible.value = false
})

function onResize() {
// 浏览器宽度小于1550时，左侧菜单隐藏文字
  isCollapse.value = document.body.clientWidth < 1550
}

function openMenuDrawer() {
  menuVisible.value = !menuVisible.value
}

onMounted(()=>{
  // 处理左上角选择的菜单名称
  // 单独处理烟草模块的图片列表和结果列表
  if (route.name === 'imgListView' || route.name === 'detectionResult')
    selectKey.value = 'detectionPicture'
  // 单独处理bi chat
  else if (route.name === 'biChat' || route.name === 'rawData')
    selectKey.value = 'biChat'
  else
    selectKey.value = route.name

  const menuList = recommendList.value.filter((item)=>{
    return item.name === selectKey.value
  })

  menuObj.value = menuList[0]
  if (localStorage.getItem('token')) {
    isLogin.value = true
    mobile.value = localStorage.getItem('mobile')
  }
  // 检测如果是在会话页面token失效，就自动登录
  if (localStorage.getItem('loginVisible') === 'true') {
    loginVisible.value = true
    isLogin.value = false
  }
  window.addEventListener('setItemEvent', (e: any) => {
    if (e.key === 'loginVisible') {   // tableData 是需要监听的键名
      // 这里的newValue就是localStorage中，键名为tableData对应的变化后的值。
      loginVisible.value =  JSON.parse(e.newValue)
      isLogin.value = ! JSON.parse(e.newValue)
    }
  })

  window.addEventListener('resize', onResize, false)
})

onUnmounted(() => {
  window.removeEventListener('resize', onResize)
})
</script>

<template>
  <section class="default-layout relative overflow-hidden">
    <a-layout>
      <div id="headerTop" class="layout-home h-[100vh] overflow-hidden bg-[#EAEFF8]">
        <!-- 头部 -->
        <a-layout-header>
          <div class="relative header-top h-[54px] flex items-center px-[20px]">
            <div class="flex items-center cursor-pointer" @click="goHome">
              <img class="w-[117px]" src="../assets/img/layout/uran-icon.svg" alt="">
              <!-- <img class="w-[195px]" src="../assets/img/layout/uran-yancao-icon.svg" alt=""> -->
              <!-- <img class="w-[195px]" src="../assets/img/layout/uran-traffic-icon.svg" alt=""> -->
            </div>
            <div class="w-[1px] h-[16px] bg-[#DDE1E6] mx-[16px]" />
            <div class="flex items-center mr-[16px]">
              <img :src="menuObj?.url" class="h-[24px] w-[24px] mr-[8px]">
              <span class="text-[#2D3036] text-[18px] font-semibold">{{ menuObj?.title }}</span>
            </div>
            <!-- <i class="iconfont icon-application cursor-pointer mt-[2px]" style="font-size: 20px;" @click="openMenuDrawer" />  -->
            <div class="flex-1" />
            <a-tooltip content="意见反馈">
              <i class="iconfont icon-Frame-171 cursor-pointer mr-[24px]" style="font-size: 24px;" @click="goFeedback" />
            </a-tooltip>
            <!-- <i class="iconfont icon-Frame-181 cursor-pointer mr-[24px]" style="font-size: 24px;" /> 更换主题 -->
            <img v-if="!isLogin" class="w-[32px] h-[32px] cursor-pointer" src="../assets/img/layout/not-logged.png" alt="" @click="login()">
            <a-dropdown v-else :popup-max-height="false">
              <div class="flex items-center mr-[4px]">
                <img class="w-[32px] h-[32px] cursor-pointer" src="../assets/img/layout/header-logo.svg" alt="">
                <!-- <icon-down class="cursor-pointer ml-[8px]" /> -->
              </div>
              <template #content>
                <a-doption>{{ mobile }}</a-doption>
                <a-doption @click="logout">
                  退出登录
                </a-doption>
              </template>
            </a-dropdown>
          </div>
          <div class="h-[1px] bg-[#D1D9E4]" />
        </a-layout-header>
        <div class="h-[calc(100vh-54px)] relative">
          <RouterView />
        </div>
      </div>
    </a-layout>
    <!-- 登录弹框 -->
    <div class="login-model">
      <a-modal v-model:visible="loginVisible" :hide-cancel="true" :render-to-body="false" @cancel="loginVisible = false">
        <template #title />
        <div class="login-content mt-[-24px]">
          <div class="text-[22px] font-semibold mb-[24px]">
            短信登录
          </div>
          <a-form ref="loginForm" :model="form" :rules="rules">
            <a-form-item field="phone" label="" hide-asterisk :hide-label="true">
              <a-input v-model="form.phone" allow-clear placeholder="请输入手机号">
                <!-- <template #prefix>
                  <div class="flex items-center">
                    <span class="font-semibold mr-[8px]">+86</span>
                    <img src="../assets/img/layout/dao-san.png" alt="">
                    <div class="w-[1px] h-[26px] bg-[#DBDBDB] ml-[12px]" />
                  </div>
                </template> -->
                <template #suffix>
                  <img class="w-[18px] h-[18px]" src="../assets/img/layout/phone-call.png" alt="">
                </template>
              </a-input>
            </a-form-item>
            <a-form-item field="code" label="" :hide-label="true" class="mt-[16px]">
              <a-input v-model="form.code" allow-clear placeholder="请输入短信验证码">
                <template #suffix>
                  <a-button v-if="!isSendCode" type="text" class="text-[15px]" @click="sendCaptcha">
                    获取验证码
                  </a-button>
                  <span v-else-if="countdown > 0">{{ countdown }}s</span>
                  <a-button v-else type="text" class="text-[15px]" @click="sendCaptcha">
                    重新发送
                  </a-button>
                </template>
              </a-input>
            </a-form-item>
          </a-form>
        </div>
        <template #footer>
          <div class="flex items-center">
            <a-checkbox v-model="checkedCode" />
            <div class="text-[#86909C] ml-[8px]">
              已阅读并同意 <span class="text-[#165DFF] cursor-pointer" @click="serviceAgreement">《服务协议》</span>和 <span class="text-[#165DFF] cursor-pointer" @click="privacyPolicy">《隐私政策》</span>
            </div>
          </div>

          <div class="h-[50px] login-but mb-[16px] flex items-center justify-center text-[18px]" :class="form.phone && form.code && checkedCode ? 'isActive' : ''" @click="loginRegister">
            登录
          </div>
          <div class="text-[#86909C] text-center mb-[32px]">
            验证登录后将自动注册账号
          </div>
        </template>
      </a-modal>
    </div>
    <!-- 菜单 -->
    <div
      v-if="menuVisible"
      style="z-index: 999;"
      class="menu-main absolute top-[54px]"
    >
      <div class="menu-content h-[216px] w-[100%]">
        <div class="max-w-[1000px] flex flex-wrap m-auto">
          <div v-for="(item, index) in recommendList" :key="`menu${index}`" class="recommd-item w-[300px] flex px-[16px] py-[12px] mt-[20px] mr-[24px]" :class="selectKey === item.name ? 'bg-[#DFE9F8] rounded-[4px]' : ''" @click="linkeToRoute(item.name, index)">
            <img class="w-[40px] h-[40px] mr-[16px]" :src="item.url">
            <div>
              <div class="text-[#0D0C22] text-[14px] font-semibold">
                {{ item.title }}
              </div>
              <div class="w-[180px] over-one text-[#4E5969] text-[14px]" :title="item.desc">
                {{ item.desc }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="menu-mask" @click="menuVisible = false" />
    </div>
  </section>
</template>

<style scoped>
@keyframes fadeOutY{
    from {
      opacity: 1;
      transform: translateY(0px);
    }

    to {
      opacity: 0;
      transform: translateY(-50px);
    }
  }
@keyframes fadeInY{
    from {
      opacity: 0;
      transform: translateY(-50px);
    }

    to {
      opacity: 1;
      transform: translateY(0px);
    }
  }
  @keyframes fadeBg{
    from {
      background: none;
    }

    to {
      background: rgba(0, 0, 0, 0.3);
    }
  }
  .fadeInY{
    animation-name: fadeInY;
  }
  .fadeOutY{
    animation-name: fadeOutY;
  }
.menu-main{
  width:100%;
  height: 100vh;
  
  .menu-content{
    background: rgba(243, 247, 253, 0.80);
    backdrop-filter: blur(7.5px);
    animation: fadeInY 0.5s;
    animation-fill-mode: forwards;
    .recommd-item{
      cursor: pointer;
      &:hover{
        border-radius: 4px;
        background: #DFE9F8;
      }
    }
  }
  .menu-mask{
    height: calc(100% - 216px);
    background: rgba(0, 0, 0, 0.3);
    animation: fadeBg 0.5s;
    animation-fill-mode: forwards;
  }
}
.header-top{
  background: url("../assets/img/layout/header-bg.png");
  background-size: 100% 100%;
  :deep(.arco-drawer){
    background: none;
  }
  :deep(.arco-drawer-body){
    
    background: rgba(243, 247, 253, 0.80);
    backdrop-filter: blur(7.5px);
  }
  :deep(.arco-drawer-mask){
    height: calc(100% - 216px);
    top:216px;
    background: rgba(0, 0, 0, 0.3);
  }
  :deep(.arco-drawer-container){
    height: calc(100vh - 54px);
  }

}
.login-model{
  .arco-menu-vertical .arco-menu-item:not(.arco-menu-has-icon){
    white-space: break-spaces !important;
  }
  :deep(.arco-modal){
    width: 433px;
  }
  :deep(.arco-modal-footer){
    padding:16px 32px
  }
  :deep(.arco-modal-body){
    text-align:left;
    padding:24px 32px
  }
  :deep(.arco-modal-header){
     border: none;
  }
  :deep(.arco-modal-footer){
    border:none;
    text-align: left;
    margin-top:-24px
  }
  :deep(.arco-input-wrapper){
    height: 62px;
  }
  :deep(.arco-btn-text){
    font-weight:600;
    color:#000;
    font-size:15px;
    &:hover{
      background:none;
    }
  }
  .login-but{
    color: rgba(255, 255, 255, 0.5);
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    text-align:center;
    padding:16px 0px;
    border-radius: 4px;
    margin-top:8px;
    cursor:pointer;
    background: linear-gradient(0deg, #165DFF 0.03%, #1F94FF 108.47%);
    box-shadow: 0px 4px 8px 2px rgba(0, 0, 0, 0.10);
    &:hover{
      color: #fff;
    }
  }
  .isActive{
    color: #fff;
  }
}
.logo-bgColor{
    font-size:22px;
    background: var(--Linear, linear-gradient(130deg, #FCE045 1.05%, #CC1F30 35.67%, #2A0471 68.76%, #3543E7 104.59%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-right:8px;
}
.logo-font{
  font-size:28px;
  font-weight:bold;
  margin-top:8px;
}
.layout-side{
  background-color: rgba(82, 92, 132, 0.1);
}
</style>

