import { createApp } from 'vue'
import { PiniaVuePlugin } from 'pinia'
import '@/styles/index.less'
import githubTheme from '@kangc/v-md-editor/lib/theme/github.js'
import VMdPreview from '@kangc/v-md-editor/lib/preview'

import ArcoVue from '@arco-design/web-vue'
import ArcoVueIcon from '@arco-design/web-vue/es/icon'

import hljs from 'highlight.js'
import App from './App.vue'
import router from './router'
import store from './store'
import './style.css'
import '@/utils/rem'
import 'animate.css'
import '@kangc/v-md-editor/lib/style/preview.css'
import '@kangc/v-md-editor/lib/theme/style/github.css'
import '@kangc/v-md-editor/lib/style/base-editor.css'
import '@/assets/font/iconfont.css'
import 'virtual:svg-icons-register'

// 额外引入图标库
import '@arco-design/web-vue/dist/arco.css'

import dispatchEventStroage from './utils/watchLocalStorage'

VMdPreview.use(githubTheme, {
  Hljs: hljs,
})


const app = createApp(App)
app.use(PiniaVuePlugin)
app.use(router)
app.use(store)
app.use(VMdPreview)
// app.use(directive)
// 使用arco图标
app.use(ArcoVue)
app.use(ArcoVueIcon)
router.isReady().then(() => app.mount('#app'))
app.use(dispatchEventStroage)
