import type { AxiosRequestConfig } from 'axios'
import type {
  RequestMethod,
  RequestResult,
} from './index.interface'
import GlobalAxiosInstance from './instance'
import { getRequestResponse } from '@/utils/requestUtils'

interface RequestParam {
  url: string
  method?: RequestMethod
  data?: any
  params?: any
  axiosConfig?: AxiosRequestConfig
}

export function createRequest(axiosConfig: AxiosRequestConfig) {
  const requestInstance = new GlobalAxiosInstance(axiosConfig)

  async function asyncRequest<T>(param: RequestParam): Promise<RequestResult<T>> {
    const { url } = param
    const method = param.method || 'get'
    const { instance } = requestInstance
    const res = await getRequestResponse({
      instance,
      method,
      url,
      data: param.data,
      config: param.axiosConfig,
    }) as RequestResult<T>

    return res
  }

  // get
  function get<T>(url: string, axiosConfig?: AxiosRequestConfig) {
    return asyncRequest<T>({
      url,
      method: 'get',
      axiosConfig,
    })
  }

  // post
  function post<T>(url: string, data?: any, config?: AxiosRequestConfig) {
    return asyncRequest<T>({
      url,
      method: 'post',
      data,
      axiosConfig: config,
    })
  }

  // put
  function put<T>(url: string, data?: any, config?: AxiosRequestConfig) {
    return asyncRequest<T>({ url, method: 'put', data, axiosConfig: config })
  }

  // delete
  function handleDelete<T>(url: string, config?: AxiosRequestConfig) {
    return asyncRequest<T>({ url, method: 'delete', axiosConfig: config })
  }

  return {
    get,
    post,
    put,
    delete: handleDelete,
  }
}
