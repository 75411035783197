<script setup lang="ts">
import { onMounted, reactive } from 'vue'

function onResize() {
  // 这里可以进行一些响应式的操作 根据浏览器的分辨率来改变一些样式
  // document.querySelector('html').style.fontSize = `${192 * window.devicePixelRatio  }px`
}

onMounted(()=>{
  window.addEventListener('resize', onResize, false)
})

onUnmounted(() => {
  window.removeEventListener('resize', onResize, false)
})
</script>

<template>
  <RouterView />
</template>

<style scoped>
</style>
