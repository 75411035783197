import { Message } from '@arco-design/web-vue'
import type { FailedResult, RequestError, SuccessResult } from './index.interface'
import { ERROR_MSG_DURATION, NO_MSG_ERROR_CODE } from '@/config/service'

/**
 * 构造最终的请求结果
 */
export async function handleServiceResult<T = any>(error: RequestError | null, data: any, status?: number) {
  if (error) {
    const fail: FailedResult = {
      error,
      data: null,
      status,
    }
    return fail
  }
  const success: SuccessResult<T> = {
    error: null,
    data,
    status,
  }
  return success
}

/**
 * 处理显示错误提示，防止重复
 */
const errorMsgStack = new Map<string | number, string>([])
function addErrorMsg(error: RequestError) {
  errorMsgStack.set(error.code, error.msg)
}
function removeErrorMsg(error: RequestError) {
  errorMsgStack.delete(error.code)
}
function hasErrorMsg(error: RequestError) {
  return errorMsgStack.has(error.code)
}

export function showErrorMsg(error: RequestError) {
  if (!error.msg || hasErrorMsg(error) || NO_MSG_ERROR_CODE.includes(error.code))
    return
  console.warn(error.code, error.msg)
  Message.error(error.msg)
  addErrorMsg(error)
  setTimeout(() => {
    removeErrorMsg(error)
  }, ERROR_MSG_DURATION)
}
