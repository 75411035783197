import { createRouter, createWebHistory } from 'vue-router'
import { routes } from './modules'

import { NOT_FOUND_ROUTE } from './base'
import Layout from '@/layout/index.vue'
import EmptyLayout from '@/layout/Empty.vue'

export const base = '/large-model'

const router = createRouter({
  history: createWebHistory(base),
  routes: [
    {
      path: '/',
      name: 'homePage',
      component: EmptyLayout,
      redirect: '/home',
      meta:{
        title:'主页',
      },
      children:[{
        path: 'home',
        name: 'homePage',
        component: () => import('@/views/largeModel/home/index.vue'),
      }],
    },
    {
      path: '/',
      name: 'LayoutIndex',
      component: Layout,
      children: routes,
    },
    // 邀测界面
    {
      path: '/invitation',
      name: 'invitationTest',
      component: () => import('@/views/invitationTest.vue'),
    },
    //服务协议
    {
      path: '/serviceAgreement',
      name: 'serviceAgreement',
      component: () => import('@/views/serviceAgreement.vue'),
    },
    //隐私政策
    {
      path: '/privacyPolicy',
      name: 'privacyPolicy',
      component: () => import('@/views/privacyPolicy.vue'),
    },
    //意见反馈
    {
      path: '/feedback',
      name: 'feedback',
      component: () => import('@/views/feedback.vue'),
    },
    // 新年祝福
    {
      path: '/newYearWishes',
      name: 'newYearWishes',
      component: () => import('@/views/newYearWishes.vue'),
    },
    // h5: 新年快乐
    {
      path: '/h5/happyNewYear',
      name: 'happyNewYear',
      component: () => import('@/views/h5/newYear-2024/HappyNewYear.vue'),
    },
    // h5: 新年祝福
    {
      path: '/h5/bestWishes',
      name: 'bestWishes',
      component: () => import('@/views/h5/newYear-2024/BestWishes.vue'),
    },
    NOT_FOUND_ROUTE,
  ],
  scrollBehavior() {
    return {
      el: '#app',
      top: 0,
      behavior: 'smooth',
    }
  },
})

export default router
