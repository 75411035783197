/** 请求超时时间 */
export const REQUEST_TIMEOUT = 60 * 1000

/** 错误提示的持续时间 */
export const ERROR_MSG_DURATION = 3 * 1000

/** 请求错误提示文字 */
export const DEFAULT_REQUEST_ERROR_TEXT = '请求失败，请稍后重试'

/** 请求错误提示code */
export const DEFAULT_REQUEST_ERROR_CODE = 'REQUEST_ERROR'

/** 网络不可用的code */
export const NETWORK_ERROR_CODE = 'NETWORK_ERROR'

/** 网络不可用的code */
export const NETWORK_ERROR_TEXT = '网络不可用，请检查网络连接'

/** 后端各类错误状态的提示文字 */
export const ERROR_STATUS_HASH = {
  401: '登录已过期，请重新登录',
}

/** 不需要提示的code */
export const NO_MSG_ERROR_CODE: (string | number)[] = []
