const routers = {
  path: '/',
  name: 'index',
  component: () => import('@/views/layout/index.vue'),
  redirect: '/home',
  children: [
    {
      path: '/chatMessage',
      name: 'chatMessage',
      component: () => import('@/views/largeModel/chatMessage.vue'),
      meta:{
        title:'会话机器人',
      },
    },
    {
      path: '/biAssistant',
      name: 'biChat',
      component: () => import('@/views/largeModel/biAssistant.vue'),
      meta:{
        title:'biChat',
      },
    },
    {
      path: '/rawData',
      name: 'rawData',
      component: () => import('@/views/largeModel/rawData.vue'),
      meta:{
        title:'原始数据',
      },
    },
    {
      path: '/technologyAi',
      name: 'technologyAi',
      component: () => import('@/views/largeModel/technologyAi.vue'),
      meta:{
        title:'工艺智卡',
      },
    },
    {
      path: '/intellectualAnalysis',
      name: 'intellectualAnalysis',
      component: () => import('@/views/largeModel/IntellectualAnalysis.vue'),
      meta:{
        title:'智析视界',
      },
    },
    {
      path: '/detectionPicture',
      name: '',
      component: () => import('@/views/layout/index.vue'),
      meta:{
        title:'',
      },
      children:[
        {
          path: 'index',
          name: 'detectionPicture',
          component: () => import('@/views/largeModel/detectionPicture/index.vue'),
          meta:{
            title:'智检图析',
          },
        },
        {
          path: 'imgListView',
          name: 'imgListView',
          component: () => import('@/views/largeModel/detectionPicture/components/imgListView.vue'),
          meta:{
            title:'上传图片',
          },
        },
        {
          path: 'detectionResult',
          name: 'detectionResult',
          component: () => import('@/views/largeModel/detectionPicture/components/detectionResult.vue'),
          meta:{
            title:'分析结果',
          },
        },
      ],
    },
    {
      path: '/traffic',
      name: 'traffic',
      component: () => import('@/views/largeModel/traffic/index.vue'),
      meta: {
        title: '交通智行',
      },
      redirect: '/traffic/list',
      children: [
        {
          path: '/traffic/list',
          name: 'trafficList',
          component: () => import('@/views/largeModel/traffic/list/index.vue'),
        },
        {
          path: '/traffic/config',
          name: 'trafficConfig',
          component: () => import('@/views/largeModel/traffic/flow/index.vue'),
        },
      ],
    },

  ],
}

export default routers
